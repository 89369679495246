import { Box, styled } from "@mui/material";
import { isMobile } from "react-device-detect";
import { Container, Content, Header } from "../../components/Styles/Styles";
import ServiceCardHolder from "../Cards/ServiceCardHolder";

const Wrapper = styled(Box)(({theme})=>({
    width: "95%",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
        textAlign: "center"
        
    }

}))

export default function OurServices() {
    return (
        <Container accent>
            <Content>
                <Wrapper>
                    <Header align={isMobile ? "center" : "left"}>Our Services</Header>
                    <p className={isMobile ? "primary-main os_content center fs_20" : "primary-main os_content"}>
                        Knymbus is a software development company that specializes in
                        software, web and mobile app development. Knymbus’ approach to development is very
                        thorough and detail oriented as we aim to satisfy the needs of our clients.
                        We believe when our clients are involved in the development process,
                        it helps them to become more invested as they anticipate the final product.</p>
                </Wrapper>
            </Content>
            <ServiceCardHolder />
        </Container >
    )
}