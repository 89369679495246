import { API } from "../../services/API";
import { axiosExecute, AxiosOptions } from "./AxiosExecute";

type ConfigType = "contact"


interface RecipientName {
    /**
     * The first name of the recepient
     */
    firstname: string;
    /**
     * The last name of the recepient
     */
    lastname: string;
    /**
     * The full name of the recepient
     */
    fullname?: string
}

interface SourceHostAddress {
    /**
     * name of to display in your email address
     */
    name: string
    /**
     * first part of the email
     */
    prefix: string
    /**
     * domain name with @ symbol
     */
    suffix: string
}

interface ConfigSender {
    /**
     * The host address, that is the owners of the website 
     * example. site addrerss http://www.myDomain.com => some email address on the domain info@mydomain.com
     */
    address: string,
    /**
     * Business name of the site/company example MyStore.com
     * name = "MyStore". Once this is set Recepients will see address as follow.
     * MyStore <info@mystore.com>
     */
    name?: string,
    /**
     * This attribute allow you to send dynamic email to your recepients with dynamic email such as 
     * info-OQKEIdINuyOnsB2lUFjaLZeY@mystore.com. Default value false therefore address will be used as is.
     */
    dynamicAddress?: boolean

}

interface ConfigAutomaticResponse {
    /**
     * The email subject of the automatic response email that will be send to user 
     */
    subject: string,
    /**
     * You can indicate the message in plain text that you would like to send to the customer
     */
    text?: string,
    /**
     * You can indicate the message in HTML string that you would like to send to the customer
     */
    html?: string
    /**
     * Automatic source house address
     */
    source: SourceHostAddress


}

interface KReachOutConfigOptions {
    /**
     * The ID attribute allow the user to retrieve all the email that was send to them under the id field
     */
    id: string
    /**
     * Type attribute tell the system which type of message you would like to send 
     * Think about this attribute like a category
     */
    type: ConfigType
}
export class KReachOut {
    private _id: string | undefined;
    private _name: RecipientName | string = "" // Name of the recipient
    private _message: string = ""
    private _recipient: string = "" //Email address of the person that want to send you a message

    private _type: ConfigType | undefined;
    

    constructor(args?: KReachOutConfigOptions) {
        if (args !== undefined && args !== null) {
            this._id = args.id
            this._type = args.type
        }
    }

    set setRecipientName(value: RecipientName) {
        this._name = value
    }

    set setMessage(message: string) {
        this._message = message
    }

    set setRecipient(value: any) {
        this._recipient = value
    }

    // Methods
    public send() {
        return new Promise(async (resolve, reject) => {
            // setup payload to be sent to server
            let payload: any = this._getPayload()

            // console.table("Payload: ",payload)

            API.set(payload).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }

    // private Methods
    private _getPayload() {
        let payload = {
            clientId: this._id,
            name: this._name,
            message: this._message,
            email: this._recipient,
            createdAt: new Date().toISOString()
        }

        return payload
    }
}

