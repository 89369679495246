import "./menu.css"
import logo from "../../images/logo.png"

const SiteMenuMobile = ()=>{
    return (
        <div className="site_menu_wrapper">
            
            <div className="site_name">
            <div className="icon"><img src={logo} style={{width: "30px", height: "30px"}}/></div>Knymbus</div>
            {/* <div className="site_menu">
                <ul>
                    <li>Product</li>
                    <li>Contact</li>
                    
                </ul>
            </div> */}
        </div>
    )
}

export default SiteMenuMobile