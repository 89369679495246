

import "./Contact.css"
import { Container, Content, Header, Row } from "../../components/Styles/Styles";
import { Box, styled } from "@mui/material";
import { isMobile } from 'react-device-detect'
import ContactForm from "./ContactForm";



const Wrapper = styled(Box)(({theme})=>({
    display: "flex",
    flexDirection: isMobile ? "column" : "row",

    [theme.breakpoints.down("md")]:{
        display:"block"
    }
}))

const Section = styled(Box)(({ theme }) => ({
    width: isMobile ? "95%" : "400px",
    margin: "0 auto",
    paddingRight: isMobile ? 0 : "150px",
    lineHeight: "20pt",
    textAlign: isMobile ? "center" : "left",
    [theme.breakpoints.down("md")]:{
        width: "95%",
        textAlign: "center",
        padding: 0
    }
}))


export default function Contact() {
    return (
        <Container accent id="contactForm">
            <Content>
                <Wrapper>
                    <Section>

                        <Header align={isMobile ? "center" : "left"}>Contact us</Header>
                        <Row height={20} />
                        <Box sx={{
                            fontFamily: "Palanquin, 'sans-serif'",
                            fontSize: '15pt',
                            lineHeight: `28px  !important`,
                            color: 'var(--dark-blue)'
                        }}>
                            Developing a custom software application is serious business and you need the right partners. Partner with us today to create your unique solution.
                        </Box>
                        <Row height={16} />
                    </Section>


                    {/* Contact Form */}
                    <ContactForm />

                    <Row height={50} />
                </Wrapper>
            </Content>
        </Container>
    )
}