import { Box, styled } from "@mui/material"
import ActionButton from "../../../components/ActionButton"
import MainHeader from "../../../components/MainHeader"
import { Row } from "../../../components/Styles/Styles"
import bImg from "../../../images/bimg1.svg"


const Container = styled(Box)(({theme})=>({
    margin: "60px auto",
    width: "80%",
    height: "90Vh",
    padding: "30px 16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overFlow: "hidden",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "auto",
        
        
    }


}))

const Graphics = styled(Box)(({theme})=>({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "0px",
    width: "45vw",

    "& img":{
        width: "480px", 
        height: "480px"
    },
    [theme.breakpoints.down("md")]:{
        width: "100%",
        marginTop: "50px",

        "& img":{
            width: "340px", 
            height: "340px"
        }
    }

}))


const Info = styled(Box)(({theme})=>({
    width: "50vw",
    [theme.breakpoints.down("md")]:{
        width: "100%"
    }
}))

const Title = styled(Box)({
    fontFamily: `'Fira Sans Condensed', sans-serif`,
    fontSize: '30pt',
    fontWeight: 400,
    letterSpacing: "1.2px"
})

const SubText = styled("p")({
    fontSize: "13pt", 
    lineHeight: "18pt", 
    color: "#000", 
    fontWeight: 400, 
    fontFamily: `'Palanquin', sans-serif`,
    
})

const MainBanner = styled(Box)(({theme})=>({
    textAlign: "left",
    width: "600px",
    [theme.breakpoints.down("md")]:{
        width: "100%"
    }
}))

export default function BannerDesktop() {

    const handleClick = (e: any)=>{
        document.getElementById('contactForm')?.scrollIntoView({
            behavior: "smooth"
        }) 
    }
    return (

        <MainHeader>
            <Container>
                <Info>
                    <MainBanner>
                        <div className="banner_title">
                            Building custom and reliable software for your business
                        </div>
                        <p style={{ fontSize: "15pt", lineHeight: "24pt", color: "#000", fontWeight: 400, fontFamily: `'Palanquin', sans-serif` }}>
                            Has your business out-grown its current software?
                            <br />
                            We can help design unique software solutions
                            to achieve your business goals.
                        </p>
                        <Box sx={{width: "300px"}}>
                        <ActionButton onClick={handleClick}>Start your journey with us today</ActionButton>
                        </Box>
                    </MainBanner>
                </Info>

            

            <Graphics>
                <img src={bImg}  />
                <Row height={"30px"} />
            </Graphics>
            </Container>
        </MainHeader>
    )
}