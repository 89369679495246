import './ProductOfferings.css'
import app from '../../images/straclou_app.png'
import { Container, Content, Header, Row } from '../../components/Styles/Styles'
import { Box, styled } from '@mui/material'
import { isMobile } from 'react-device-detect'


const Wrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: isMobile ? "column-reverse" : "row",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column-reverse",
    }
}))

const Section = styled(Box)(({ theme }) => ({
    width: isMobile ? "95%" : "auto",
    padding: "0 16px 0 0",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
        width: "95%",
        textAlign: "center"
    }
}))

const ImageContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    // background: "red",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
        paddingTop: "50px"
    }
}))

const ProductOfferings = () => {

    const handleClick = ()=>{
        window.open("https://www.straclou.com/","_blank")
    }
    return (
        <Container>
            <Content>
                <Row height={50} />
                <Header align={isMobile ? "center" : "left"}>Our Product Offerings</Header>
                <Wrapper>
                    <Section>
                        <p className={isMobile ? "primary-main  center fs_20 " : "primary-main os_content"}>
                            Straclou is a subscription-based software
                            that  will help community
                            board members and property managers to manage their communities and
                            commercial complexes respectively, in a more focused manner. The application is designed
                            to simplify your administrative processes through automation.
                        </p>

                        <div style={{ height: "30px" }}></div>
                        <Box sx={{ display: "flex", justifyContent: isMobile ? "center" : " flex-start" }}>
                            <button className='site_button' onClick={handleClick}>
                                Learn More
                            </button>
                        </Box>
                    </Section>
                    <ImageContainer>
                        <Box sx={{ marginTop: isMobile ? "50px" : 0, marginLeft: "auto", marginRight: "auto" }}>
                            <img src={app} width={isMobile ? "300px" : "470px"} />
                        </Box>
                    </ImageContainer>
                </Wrapper>
            </Content>
        </Container>
    )
}

export default ProductOfferings