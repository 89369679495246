import { Box, Grid, styled } from "@mui/material"
import ActionButton from "../../../components/ActionButton"
import MainHeader from "../../../components/MainHeader"
import bImg from "../../../images/bimg1.svg"

const Container = styled(Grid)({
    marginTop: "100px",
    padding: "16px",
    display: "flex",


})
const Info = styled(Grid)({

})
const Graphics = styled(Grid)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "60px"
})

const Title = styled(Box)({
    fontFamily: `'Fira Sans Condensed', sans-serif`,
    fontSize: '30pt',
    fontWeight: 400,
    letterSpacing: "1.2px"
})

const SubText = styled("p")({
    fontSize: "13pt", 
    lineHeight: "18pt", 
    color: "#000", 
    fontWeight: 400, 
    fontFamily: `'Palanquin', sans-serif`,
    
})

export default function BannerMobile() {

    const handleClick = (e: any)=>{
        document.getElementById('contactForm')?.scrollIntoView({
            behavior: "smooth"
        }) 
    }

    return (
        <MainHeader>
            <Container container gap={2}>

                <Info item xs={12}>
                    <Box sx={{ width: "100%" }}>
                        <Title>
                            Building custom and reliable software for your business
                        </Title>
                        <SubText>
                            Has your business out-grown its current software?
                        </SubText>

                        <SubText>
                            We can help design unique software solutions
                            to achieve your business goals.
                        </SubText>
                        <ActionButton onClick={handleClick}>Start your journey with us today</ActionButton>
                    </Box>
                </Info>

                <Graphics item xs={12}>
                    <img src={bImg} style={{ width: "100%", height: "100%" }} />
                </Graphics>
            </Container>

        </MainHeader>
    )
}