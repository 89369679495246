import { createTheme } from "@mui/material/styles"
import createPalette from "@mui/material/styles/createPalette"
import { Theme } from "@mui/system"

export const theme: Theme = createTheme({
    palette: createPalette({
        mode: "light",
        primary: {
           
            main: "#233356",
            light: "#a7d5f8", 
            


        },
        // secondary: {
        //    main: "#53D980",
        //     light: "#00B498"
        // }

    }),
    shape: {
        borderRadius: "5px"
    },
    components: {
        MuiList:{
            defaultProps: {
                disablePadding: true
            }
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
                disableRipple: true,
            
                
            },
            styleOverrides: {
                disableElevation: true,
                root: {
                    textTransform: "none",
                    borderRadius: "5px"
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                draggable: true,
                elevation: 0,
            },
            styleOverrides: {
                root: {

                }
            }
        }
    }

})

