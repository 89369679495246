import { Box, styled } from "@mui/material";
import { isMobile, isMobileOnly } from "react-device-detect";

export const Container = styled(Box)<{accent?: boolean}>(({theme, accent})=>({
    minHeight: "100vh",
    backgroundColor: accent ? "#f7f7f7":"var(--background)",
    paddingTop:  isMobileOnly ? "30px" : "120px",
    paddingBottom: "50px"
}))


export const Header = styled(Box)<{align?: "left" | "center" | "right"}>(({align, theme})=>({
    fontFamily: `'Fira Sans Condensed', sans-serif`,
    fontSize: '30pt',
    fontWeight: 400,
    letterSpacing: "1.2px",
    color: "var(--dark-blue)",
    textAlign: align || "left",
    [theme.breakpoints.down("md")]:{
        textAlign: "center"
    }
}))

export const Content = styled(Box)(({theme})=>({
    width: isMobile ? "100%" : "80%",
    margin: "20px auto",

    "& p": {
        fontSize: "24px",
        lineHeight: "1.9em"
    },
    [theme.breakpoints.down("md")]:{
        width: "100%"
    }
}))

export const Row = styled(Box)<{height?: string | number}>(({height})=>({
    height: height || 'auto'
    
}))