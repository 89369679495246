import { Box, styled } from "@mui/material"
import { useEffect } from "react"


const Wrapper = styled(Box)({
    /* background: #BDDEF7; */
    background: `var(--dark-blue)`,
    marginTop: "24px",
    padding: "16px",
    /* height: 0px; */
    textAlign: "center",
    width: "auto",
    /* margin: 0 auto; */
    borderRadius: "15px",
    /* color: #233356;     */
    color: "var(--light-blue)",
    zIndex: 999999,
    cursor: "pointer",
    fontSize: "14pt",
    /* box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px; */
    // boxShadow: "rgb(50, 50, 93) 0px 10px 10px -10px",
    fontFamily: "'Fira Sans Condensed', sans-serif",

    "&:hover":{
        boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px"
    }
    
})



export default function ActionButton(props: any) {
    const { children, onClick } = props

    return (
        <Wrapper onClick={onClick}>
            {
                children
            }
        </Wrapper>
    )
}