import { Box, styled } from "@mui/material"

const Wrapper = styled(Box, {name: "Main-Header"})({
    background: "#fff",
    display: "flex",
    fontSize: "calc(10px + 2vmin)",
    color: "#233356",
    fontFamily: `'Fira Sans', sans-serif`,
    position: 'relative',
    backgroundSize: "cover",
    minHeight: "100vh"
})

export default function MainHeader(props: any) {
    const { children } = props
    return (
        <Wrapper>{children}</Wrapper>
    )
}