import { isMobile } from "react-device-detect"

const PageFooter = () => {
    return (
        <div style={{ paddingLeft: "8px", display: "flex", flexDirection: isMobile ? "column-reverse" : "row" }}>
            <div style={{ marginTop: isMobile?'8px' : "0px" }} className="white">Copyright © 2022 Knymbus. All rights reserved. </div>
            <div style={{
                marginLeft: isMobile ? 0 : "8px"
            }}>Credit: illustration by Storyset from <a style={{ color: "#fff" }} href="https://storyset.com/people">www.storyset.com</a> </div>
        </div>
    )
}


export default PageFooter