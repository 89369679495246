import { Box, styled } from "@mui/material"
import { useEffect, useState } from "react"


const Success = styled(Box)<{ show: boolean }>(({ theme, show }) => ({
    transition: "500ms",
    width: "100%",
    height: "auto",
    position: "relative",
    fontFamily: `'Palanquin', sans-serif`,
    fontSize: "12pt",
    background: "rgb(0 20 255 / 10%)",
    color: "rgb(69 87 211)",
    borderRadius: "5px",
    justifyContent: "center",
    alignItems: "center",
    display: show ? "flex" : "none",


    "&::before": {
        content: '""',
        background: "blue",
        position: "absolute",
        width: "0%",
        left: 0,
        top: 0,
        bottom: 0

    }
}))

const Message = styled(Box)({
    padding: "10px",
})



export default function SuccessHandler(props: any) {
    const { isSuccess } = props
    const [show, setShow] = useState<boolean>(false)



    useEffect(() => {
        if (isSuccess !== undefined && isSuccess !== null) {
            setShow(isSuccess)

            setTimeout(() => {
                setShow(false)
            }, 4000);
        }
    }, [isSuccess])
    return (
        <Success show={show}>
            <Message>
                Your Message was sent succesfully 
            </Message>
        </Success>
    )
}



