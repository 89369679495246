import { isDesktop } from 'react-device-detect';
import SiteMenuDesktop from './SiteMenuDesktop';
import SiteMenuMobile from './SiteMenuMobile';

const SiteMenu = ()=>{
    return (
        <>
        {
            !isDesktop ? <SiteMenuMobile /> : <SiteMenuDesktop />
        }
        </>
    )
}

export default SiteMenu