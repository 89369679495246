import { Box, styled } from "@mui/material"
import { isMobile } from "react-device-detect"
import { OURSERVICE } from "./Service.data"
import ServiceCard from "./ServiceCard"
import './ServiceCard.css'

const Wrapper = styled(Box)(({theme})=>({
    width: isMobile? "100%" : "80%",
    height: isMobile? "1240px" : "auto",
    margin: "90px auto",
    display: "flex",
    flexDirection: isMobile? "column": "row",
    // background: "pink",
    justifyContent: "space-evenly",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
        width: "100%",
        height: "1240px",
        flexDirection: "column"
    }

}))

interface IProps {
    children?: React.ReactNode
}
const ServiceCardHolder: React.FC<IProps> = () => {
    
    return (
        <Wrapper>
            {
                OURSERVICE.map(d=>{
                    return <ServiceCard key={d.key} data={d}/>
                })
            }
            
        </Wrapper>
    )
}

export default ServiceCardHolder

