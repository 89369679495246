import wdd from '../../images/wdd.svg'
import mad from '../../images/mad.svg'
import sdc from '../../images/sdc.svg'
import {v4 as uuidV4} from 'uuid'
export const OURSERVICE = [
    {
        key: uuidV4(),
        icon: {
            src:sdc,
        },
        title: "Software Development & Consultancy",
        detail: `We enjoy bringing our clients' vision to life. Our first step involves 
        consultation for information gathering to develop and design the software to 
        meet the immediate and future needs of our client based on trends in the client's industry.`
    },
    {
        key: uuidV4(),
        icon: {src: wdd},
        title: "Web App Development",
        detail:`A web application goes a step further than a website as it is designed 
        for interaction with end users. We can help you to design, create and implement 
        a tailored web app solution that contains features and functionalities that can 
        be used for internal and external purposes. `
    },
    {
        key: uuidV4(),
        icon: {
            src:mad,
            width: '40px'
        },
        title: "Mobile App Development",
        detail: `Smartphones have become ubiquitous and it is important to capitalize on 
        this opportunity to bring a continuous experience to customers. 
        Our apps are built with optimization in mind to provide a great user experience.`
    }
]