import { Box, styled } from "@mui/material"
import { useEffect, useState } from "react"


const Error = styled(Box)<{ show: boolean }>(({ theme, show }) => ({
    width: "100%",
    height: "auto",
    position: "relative",
    fontFamily: `'Palanquin', sans-serif`,
    fontSize: "12pt",
    background: "rgba(255, 0, 0, .10)",
    color: "rgba(255, 0, 0, 1)",
    borderRadius: "5px",
    justifyContent: "center",
    alignItems: "center",
    display: show ? "flex" : "none",


    "&::before": {
        content: '""',
        background: "red",
        position: "absolute",
        width: "0%",
        left: 0,
        top: 0,
        bottom: 0

    }
}))

const Message = styled(Box)({
    padding: "10px",
})

const Hint = styled(Box)(({ theme }) => ({
    color: "red"
}))

export default function ErrorHandler(props: any) {
    const { isError } = props
    const [show, setShow] = useState<boolean>(false)

    useEffect(() => {
        if (isError !== undefined && isError !== null) setShow(isError)
    }, [isError])
    return (
        <Error show={show}>
            <Message>
                Please enter your correct details in all the mandatory fields and try again.
            </Message>
        </Error>
    )
}



export function ErrorHint(props: any) {
    const { message } = props
    return (
        <Hint>
            {message}
        </Hint>
    )
}