import { isMobile } from "react-device-detect";
import BannerDesktop from "./BannerDesktop";
import BannerMobile from "./BannerMobile";

export default function Banner(){
    return (
        <>
        {
            isMobile ? <BannerMobile /> : <BannerDesktop/>
        }
        </>
    )
}