import { useState } from "react";
import { Box, Grid, Stack, styled } from "@mui/material";
import { isMobile } from "react-device-detect";
import ErrorHandler, { ErrorHint } from "./ErrorHandler";
import config from "./contact-config";
import SuccessHandler from "./SuccessHandler";
import { LoadingButton } from "@mui/lab";



// Styles

const Wrapper = styled(Box)(({ theme }) => ({
    width: isMobile ? "100%" : "500px",
    [theme.breakpoints.down("md")]: {
        width: "100%"
    }
}))
const Form = styled('form', { name: 'contact-form' })(({ theme }) => ({
    width: "95%",
    margin: "0 auto"

}))

const FormControlWrapper = styled(Box)({
    width: "100%",
})

const FormInput = styled('input')(({ theme }) => ({
    width: "100%",
    boxSizing: "border-box",
    height: "50px",
    margin: 0,
    padding: "0 8px",
    fontFamily: `'Palanquin', sans-serif`,
    fontSize: "13pt"
}))

const FormTextarea = styled('textarea')(({ theme }) => ({
    width: "100%",
    boxSizing: "border-box",
    padding: "10px",
    minHeight: "90px",
    fontFamily: `'Palanquin', sans-serif`,
    fontSize: "13pt"
}))



interface ErrorField {
    firstname: string;
    lastname: string;
    email: string;
    message: string;
}

export default function ContactForm() {

    const [isError, setError] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isSuccess, setSuccess] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<ErrorField>({
        firstname: '',
        lastname: '',
        email: '',
        message: ''
    })

    const handleSubmit = (e: any) => {
        e.preventDefault()
        setLoading(true)
        setSuccess(false)

        // console.log("trigger")
        let ffname: HTMLInputElement = document.getElementById("ffname") as HTMLInputElement;
        let flname: HTMLInputElement = document.getElementById("flname") as HTMLInputElement;
        let fbemail: HTMLInputElement = document.getElementById("fbemail") as HTMLInputElement;
        let fmessage: HTMLTextAreaElement = document.getElementById("fmessage") as HTMLTextAreaElement;

        // reset the error message before we evaluate the new process
        setErrorMessage({
            firstname: '',
            lastname: '',
            email: '',
            message: ''
        })

        setError(false)

        let inValid = false
        let error: any = {}
        let form: ErrorField = {
            firstname: ffname.value,
            lastname: flname.value,
            email: fbemail.value,
            message: fmessage.value,
        }

        if (ffname !== null && !ffname.checkValidity()) {
            inValid = true
            error['firstname'] = "This field is required."

        }
        if (flname !== null && !flname.checkValidity()) {
            inValid = true
            error['lastname'] = "This field is required."
        }
        if (fbemail !== null && !fbemail.checkValidity()) {
            inValid = true
            error['email'] = "Please enter a valid email."
        }
        if (fmessage !== null && !fmessage.checkValidity()) {
            inValid = true
            error['message'] = "This field is required."
        }

        if (inValid) {
            setErrorMessage({ ...errorMessage, ...error })
            setError(true)
            setLoading(false)
        }

        const ClearForm = () => {
            ffname.value = ''
            ffname.value = ''
            flname.value = ''
            fbemail.value = ''
            fmessage.value = ''
        }

        if (!inValid) {
            // console.table(form)

            config.setRecipient = form.email
            config.setMessage = form.message
            config.setRecipientName = { firstname: form.firstname, lastname: form.lastname }

            config.send().then(res => {
                setSuccess(true)
                ClearForm()
                setLoading(false)
            }).catch(err => {
                ClearForm()
                setLoading(false)
            })

        }

    }
    return (
        <Wrapper>
            <Form onSubmit={handleSubmit} noValidate>
                <Stack gap={2}>
                    <FormControlWrapper>
                        <SuccessHandler isSuccess={isSuccess} />
                    </FormControlWrapper>

                    <FormControlWrapper>
                        <ErrorHandler isError={isError} />
                    </FormControlWrapper>

                    <FormControlWrapper>
                        <ErrorHint message={errorMessage['firstname']} />
                        <FormInput id="ffname" placeholder="First Name (*)" required />
                    </FormControlWrapper>

                    <Grid item xs={12} >
                        <ErrorHint message={errorMessage['lastname']} />
                        <FormInput id="flname" placeholder="Last Name (*)" required />
                    </Grid>

                    <Grid item xs={12}>
                        <ErrorHint message={errorMessage['email']} />
                        <FormInput type="email" id="fbemail" placeholder="Business Email (*)" required />
                    </Grid>



                    <Grid item xs={12}>
                        <ErrorHint message={errorMessage['message']} />
                        <FormTextarea id="fmessage" placeholder="Tell us a bit about your software requirement(*)" required />
                    </Grid>


                    <Grid item xs={12}>
                        <div className="g-recaptcha">
                            {/* reCAPTCHA */}
                        </div>
                    </Grid>

                    <Grid item xs={12}>

                        <div className="row button_holder">
                            <LoadingButton loading={isLoading} type="submit" variant="contained" >
                                SEND MESSAGE
                            </LoadingButton>
                        </div>
                    </Grid>


                </Stack>

            </Form>
        </Wrapper>
    )
}