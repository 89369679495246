import ServiceCardHolder from "../Cards/ServiceCardHolder"
import PageFooter from "../Footer/PageFooter"
import SiteMenu from "../Menu/SiteMenu"
import ProductOfferings from "../ProductOfferings/ProductOfferings"
import '../../App.css';
import "./home.css"

import Contact from "../Contact/Contact";
import Banner from "./Banner/Banner";
import OurServices from "./OurServices";
import { useEffect } from "react";



interface IProps {
    children?: React.ReactNode
}
const Wrapper = ({ children }: IProps) => {

    useEffect(() => {
        // console.log('environment: ', ENV)
      }, []);
      
    return (
        <div className="wrapper">{children}</div>
    )
}
const HomePage = () => {
    return (
        <Wrapper>

            {/* Site Menu */}
            <SiteMenu />

            {/* Main Banner */}
            <Banner />

            {/* Our Services */}
            <OurServices />

            {/* Product Offering  */}
            <ProductOfferings />

            {/* Contact Us */}
            <Contact />

            {/* Footer */}
            <div className="dark">
                <div className="content" style={{ display: "flex", justifyContent: 'center', alignItems: 'center', height: "90px", fontSize: '10pt' }}>
                    <PageFooter />
                </div>
            </div>
        </Wrapper>
    )
}

export default HomePage