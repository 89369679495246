import { Box, styled } from '@mui/material'
import { isMobile, isMobileOnly } from 'react-device-detect'
import './ServiceCard.css'


interface IProps {
    data?: any
}

// Styles
const Wrapper = styled(Box)(({theme})=>({
    marginTop: isMobileOnly? "30px":0,
    width: "calc(300px - 32px)",
    minHeight: "400px",
    borderRadius: "5px",
    padding: "24px",
    lineHeight: "1.7em",
    /* background: rgb(251, 250, 252); */
    background: "var(--light-blue)",
    /* color: #242424; */
    color: "var(--dark-blue)",
    /* box-shadow: 0px 8px 23px -1px #8d888873;
    line
     */
     boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
    position: "relative",
    fontFamily: `'Palanquin', sans-serif`,
    [theme.breakpoints.down("md")]:{
        marginTop: "30px"
    }
}))
const ServiceCard: React.FC<IProps> = ({data})=>{
    return (
        <Wrapper>
            <div className='scw_icon'><img src={data?.icon?.src} alt="icon" width={data?.icon?.width ?? '60px'}/></div>
            <div className='scw_title'>{data?.title}</div>
            <div className='scw_desc'>{data?.detail}</div>
        </Wrapper>
    )
}

export default ServiceCard