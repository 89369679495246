// import './App.css'
import HomePage from './pages/Landing/HomePage';



function App() {
  return (
    <div style={{overflow: 'auto', width: '100vw', height: '100vh'}}>
      <HomePage />
    </div>
  );
}

export default App;
